<!--
 * @Author: lihuaruiz lihuarui@huawei.com
 * @Date: 2024-06-04 16:21:22
 * @LastEditors: lihuaruiz lihuarui@huawei.com
 * @LastEditTime: 2024-07-03 11:34:43
 * @FilePath: \vue2Admin\src\views\systemsetting\account\detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
vue
<template>
  <div>
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <!-- 多选 -->
        <!-- <el-table-column type="selection" width="55" align="center">
        </el-table-column> -->
        <!-- 中间字段 -->
        <el-table-column
          v-for="tab in tableTitle"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span @click="getItemDetail(scope.row)" class="update-btn"
              >分配</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <!-- <div> -->
      <!-- <Pagination -->
      <!-- :total="dataTotal" -->
      <!-- :page="searchInfo.page" -->
      <!-- :limit="searchInfo.limit" -->
      <!-- @pagination="onChangePages" -->
      <!-- /> -->
      <!-- </div> -->
      <!-- 弹框组件 -->
      <DateEdit ref="edit" />
    </div>
  </div>
</template>

<script>
// import DescriptionList from "../../../components/Description/index.vue"
import { getNotAssignedList, getAssignedOperate } from "../../api/common";
import router from "../../router";

export default {
  data() {
    return {
      itemDetail: {},
      activeIndex: "1",
      listData: [],
      twoId: this.$route.params.id
        ? this.$route.params.id
        : localStorage.getItem("row"),
      // 表格数据
      tableData: [],
      tableTitle: [
        // { name: "ID--1", code: "id", width: "" },
        { name: "所在城市", code: "city", width: "" },
        { name: "姓名", code: "name", width: "" },
        { name: "剩余线索量", code: "remainder", width: "" },
        { name: "手机号码", code: "phone", width: "" },
        { name: "工单上限", code: "setMeal", width: "" },
        // { name: "首次联系时间", code: "lastFollowUpTime", width: "" },
        // { name: "联系状态", code: "contactState", width: "" },
      ],
    };
  },
  components: {
    // DescriptionList,
  },
  mounted() {
    console.log(this.twoId);
    this.getTicketsList(this.twoId);
  },
  methods: {
    // 关闭标签事件
    goBack() {
      // 判断路由是否能返回
      if (window.history.length <= 2) {
        this.goHome();
      } else {
        this.$router.go(-1);
      }
    },
    //获取工单未分配列表信息
    getTicketsList(id) {
      getNotAssignedList(id).then((res) => {
        this.tableData = res.data.queryResult.list;
        this.tableData.forEach((item) => {
          item.remainder = item.setMeal - item.issue;
        });
      });
    },
    getItemDetail(row) {
      //获取单行数据详情
      // console.log("cid" + JSON.stringify(cid));
      getAssignedOperate(this.twoId, row.id).then((res) => {
        //执行成功后代码处理
        if (res.data.code == 10001) {
          router.push("/login");
          this.message.console.error(res.data.message);
        } else {
          this.itemDetail = res.data.userinfo;
          console.log(res);
          if (res.data.code == 10000) {
            this.$message({ message: "分配成功", type: "success" });
          } else {
            this.$message("分配失败");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: #409eff;
}

.detail-content {
  padding: 15px;
  margin: 20px 15px;
  background-color: #fff;
  border: 1px solid #e2e2e4;
}
.back-button {
  margin-left: 20px;
  margin-top: 20px;
}
.item {
  display: flex;
  width: 240px;
  margin-top: 25px;
}
.term {
  margin-right: 10px;
}
.detail {
  margin-right: 10px;
}
.update-btn {
  cursor: pointer;
  color: #409eff;
}
</style>
